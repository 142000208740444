<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Management - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Festivals" active/>
    </template>

    <template #actions="{ }">
      <can do="create" on="management-festivals">
        <b-button v-b-tooltip="'Create'" v-b-modal="'festival-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <festival-modal id="festival-modal" @created="addFestival"></festival-modal>
      </can>
    </template>

    <template #dropdown-options="">
      <b-dropdown-item @click="refresh">
        <font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: false, cols: 2 }"
                    :sorting="table.sorting"
                    :loading="table.loading"
                    :subscriptions="table.subscriptions"
                    :is-searchable="true"
                    :func-delete="deleteFestival"
                    :export-exclude-fields="[
                        'id',
                        'slug',
                        'zone.id',
                        'state.enabled',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Festivals', loaded: table.items.length},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Zone" label-for="zone-input">
                <v-select v-model="table.filters.zone.value"
                          input-id="zone-input"
                          :options="options.zones.items" label="name"
                          :reduce="item => item.id"
                          :loading="options.zones.loading"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Enabled" label-for="enabled-input">
                <v-select v-model="table.filters.enabled.value"
                          input-id="enabled-input"
                          :options="['true', 'false']"
                          :searchable="false"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-festival', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(zone.name)="{data}">
          <span v-if="data.item.zone">{{ data.item.zone.name }}</span>
          <span v-else class="text-danger">No Zone</span>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(createdAt)="{data}">
          <last-modified :date="data.item.createdAt" no-text />
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(state.enabled)="{data}">
          <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.state.enabled }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-festival', params: { id: data.item.id, slug: data.item.slug } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <template v-if="$can('update', 'management-festivals') || $can('delete', 'management-festivals')">
            <b-dropdown-divider/>
            <can do="update" on="management-festivals">
              <b-dropdown-item class="table-row-option-enable"
                               @click="updateFestival(data.item)">
                <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"/>
                <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
              </b-dropdown-item>
            </can>
            <can do="delete" on="management-festivals">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteFestival, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>
        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import FestivalModal from '@/views/management/festivals/FestivalModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import { listFestivals, updateFestival, deleteFestival, onCreateFestival, onUpdateFestival, onDeleteFestival } from '@/graphql/queries/festivals';
import { cascadeDeleteFestival, cascadeConfirmDeleteOptions} from '@/graphql/cascade/festival';
import settingsMixin from '@/mixins/settings.mixin';
import {listSchools} from '@/views/dashboard/queries/dashboard';
import LastModified from '@/components/LastModified.vue';

export default {
  name: 'Festivals',
  components: {
    LastModified,
    PageLayout,
    TableLayout,
    OverlayLoading,
    FestivalModal,
    vSelect
  },
  mixins: [ avatar, notify, print, settingsMixin ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'site',
            label: 'Site',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'zone.name',
            label: 'Zone',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'createdAt',
            label: 'Created',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          zone: { key: 'zone.id', value: null },
          enabled: { key: 'state.enabled', value: null },
        },
        sorting: {
          by: 'name',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      options: {
        zones: {
          items: [],
          loading: true,
        }
      },
      icon: 'fas fa-headphones',
      debug: { },
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    filterZoneOptions() {
      return [...new Set(this.table.items.map(item => item.zone?.id || null).filter(name => name !== null))].sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
    },
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: false }]
    },
  },
  async mounted() {
    this.$refs.layout.state.loading = false
    await this.listFestivals();
  },
  methods: {
    /** Table Data **/
    async listFestivals() {
      this.table.loading = true
      this.options.zones.loading = true

      let nextToken = null;
      const items = [];

      do {
        const input = {
          limit: 1000,
          nextToken: nextToken,
          filter: {
            createdAt: {
              between: [
                this.settingsStore.app.current.year.start,
                this.settingsStore.app.current.year.end]
            }
          },
        };

        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listFestivals, input));
        items.push(...response.data.listFestivals.items);
        nextToken = response.data.listFestivals.nextToken;
      }
      while (nextToken);
      this.table.items = items.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
      this.table.loading = false


      const zonesMap = new Map();
      const zonesArray = [];
      this.table.items.forEach(item => {
        const {zone} = item;
        if (zone && !zonesMap.has(zone.id)) {
          zonesMap.set(zone.id, zone);
          zonesArray.push(zone);
        }
      })

      this.options.zones.items = zonesArray.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
      if(!this.options.zones.items.some(item => item.id === this.table.filters.zone.value)) {
        this.table.filters.zone.value = null
      }
      this.options.zones.loading = false
    },
    async addFestival(festival) {
      this.table.loading = true
      this.table.items.push(festival)
      this.table.loading = false
    },
    async updateFestival(festival) {
      try {
        festival.state.enabled = !festival.state.enabled
        await API.graphql(graphqlOperation(updateFestival, { input: { id: festival.id, state: festival.state } } ));
        this.notify({ title: 'Success', text: 'Festival was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Festival failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteFestival(festival, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteFestival(festival.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== festival.id)
        this.notify({ title: 'Success', text: 'Festival was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Festival failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteFestival,

    /** Subscriptions **/
    onCreateFestival() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateFestival)).subscribe((sourceData) => {
        const festival = sourceData.value.data.onCreateFestival
        if (festival && !this.table.items.map(item => item.id).includes(festival.id)) {
          this.syncNotification()
          this.table.loading = true
          this.table.items = [festival, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateFestival() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateFestival)).subscribe((sourceData) => {
        const festival = sourceData.value.data.onUpdateFestival
        if (festival && this.table.items.map(item => item.id).includes(festival.id)) {
          this.syncNotification()
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === festival.id)
          if(index > -1) {
            this.table.items.splice(index, 1, festival)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteFestival() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteFestival)).subscribe((sourceData) => {
        const festival = sourceData.value.data.onDeleteFestival
        if(festival && this.table.items.map(item => item.id).includes(festival.id)) {
          this.syncNotification()
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== festival.id);
          this.table.loading = false
        }
      });
    },

    /** Util **/
    async refresh() {
      this.table.loading = true
      await this.listFestivals()
    },
  }
}
</script>

<style lang="scss">

</style>
