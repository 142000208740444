export const createFestival = /* GraphQL */ `
    mutation CreateFestival(
        $input: CreateFestivalInput!
        $condition: ModelFestivalConditionInput
    ) {
        createFestival(input: $input, condition: $condition) {
            id
            slug
            name
            zone {
                id
                name
            }
            createdAt
            state {
                enabled
            }
        }
    }
`;

export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const onUpdateZone = /* GraphQL */ `
    subscription OnUpdateZone {
        onUpdateZone {
            id
            name
            counties
            state {
                enabled
            }
        }
    }
`;
