// eslint-disable-next-line import/prefer-default-export
export const getUser = /* GraphQL */ `
    query GetUser(
        $id: ID!
        $includeSchools: Boolean = false
        $includeDistrict: Boolean = false
    ) {
        getUser(id: $id) {
            id
            ensembleIds
            instrumentIds
            zoneIds
            name {
                first
                last
            }
            schools @include(if: $includeSchools) {
                items {
                    schoolID
                    school {
                        id
                        name {
                            legal
                        }
                        district @include(if: $includeDistrict) {
                            name {
                                legal
                            }
                        }
                        schoolZoneId
                    }
                }
            }
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                   
                }
            }
            nextToken
        }
    }
`;


export const listApplications = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationFestivalId
                applicationFormId
                applicationInstrumentId
                applicationSelectionId
                studentApplicationsId
#                student {
#                    id
#                    name {
#                        first
#                        last
#                    }
#                    school {
#                        name {
#                            legal
#                        }
#                    }
#                }
                ranking {
                    score
                    local
                }
                createdAt
            }
            nextToken
        }
    }
`;

export const listApplicationsByTeacher = /* GraphQL */ `
    query ListApplicationsByTeacher(
        $teacherID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listApplicationsByTeacher(
            teacherID: $teacherID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                applicationFestivalId
                applicationFormId
                applicationInstrumentId
                applicationSelectionId
                studentApplicationsId
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        name {
                            legal
                        }
                    }
                }
                ranking {
                    score
                    local
                }
                createdAt
            }
            nextToken
        }
    }
`;

export const listFestivals = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                festivalZoneId
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                slug
                formInstrumentsId
            }
            nextToken
        }
    }
`;

export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;

export const listZones = /* GraphQL */ `
    query listZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;

export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    first
                    last
                }
                school {
                    name {
                        legal
                    }
                }
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
        $includeInstruments: Boolean = false
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                hasParts
                instruments @include(if: $includeInstruments) {
                    items {
                        instrumentID
                    }
                }
            }
            nextToken
        }
    }
`;

export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                accepted
                createdAt
                updatedAt
                selectionApplicationId
                selectionEnsembleId
            }
            nextToken
        }
    }
`;

