var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Management - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Festivals","active":""}})]}},{key:"actions",fn:function(ref){return [_c('can',{attrs:{"do":"create","on":"management-festivals"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Create'),expression:"'Create'"},{name:"b-modal",rawName:"v-b-modal",value:('festival-modal'),expression:"'festival-modal'"}],attrs:{"variant":"transparent","size":"sm"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-plus"}})],1),_c('festival-modal',{attrs:{"id":"festival-modal"},on:{"created":_vm.addFestival}})],1)]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-rotate-right"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('table-layout',{ref:"table-layout",attrs:{"items":_vm.table.items,"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false, cols: 2 },"sorting":_vm.table.sorting,"loading":_vm.table.loading,"subscriptions":_vm.table.subscriptions,"is-searchable":true,"func-delete":_vm.deleteFestival,"export-exclude-fields":[
                      'id',
                      'slug',
                      'zone.id',
                      'state.enabled' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
          { state: _vm.table.loading, desc: 'Loading Festivals', loaded: _vm.table.items.length},
          { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Zone","label-for":"zone-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"zone-input","options":_vm.options.zones.items,"label":"name","reduce":function (item) { return item.id; },"loading":_vm.options.zones.loading,"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.zone.value),callback:function ($$v) {_vm.$set(_vm.table.filters.zone, "value", $$v)},expression:"table.filters.zone.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Enabled","label-for":"enabled-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"enabled-input","options":['true', 'false'],"searchable":false,"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.enabled.value),callback:function ($$v) {_vm.$set(_vm.table.filters.enabled, "value", $$v)},expression:"table.filters.enabled.value"}})],1)],1)],1)]},proxy:true},{key:"cell(name)",fn:function(ref){
        var data = ref.data;
return [_c('b-media',{attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[(_vm.hasCustomAvatar(data.item.avatar))?_c('b-avatar',{directives:[{name:"b-modal",rawName:"v-b-modal.avatar-modal",modifiers:{"avatar-modal":true}}],attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('b-img',{attrs:{"fluid":"","fluid-grow":"","src":data.item.avatar.src,"alt":data.item.avatar.name}})],1):_c('b-avatar',{directives:[{name:"b-modal",rawName:"v-b-modal.avatar-modal",modifiers:{"avatar-modal":true}}],attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'management-festival', params: { id: data.item.id, slug: data.item.slug } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)],1)]}},{key:"cell(zone.name)",fn:function(ref){
        var data = ref.data;
return [(data.item.zone)?_c('span',[_vm._v(_vm._s(data.item.zone.name))]):_c('span',{staticClass:"text-danger"},[_vm._v("No Zone")])]}},{key:"cell(createdAt)",fn:function(ref){
        var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.createdAt,"no-text":""}})]}},{key:"cell(state.enabled)",fn:function(ref){
        var data = ref.data;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (data.item.state.enabled ? 'success' : 'danger'))}},[_vm._v(" "+_vm._s(data.item.state.enabled)+" ")])]}},{key:"cell(row-options)",fn:function(ref){
        var data = ref.data;
return [_c('b-dropdown-item',{staticClass:"table-row-option-view",attrs:{"to":{ name: 'management-festival', params: { id: data.item.id, slug: data.item.slug } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View")])],1),(_vm.$can('update', 'management-festivals') || _vm.$can('delete', 'management-festivals'))?[_c('b-dropdown-divider'),_c('can',{attrs:{"do":"update","on":"management-festivals"}},[_c('b-dropdown-item',{staticClass:"table-row-option-enable",on:{"click":function($event){return _vm.updateFestival(data.item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(data.item.state.enabled ? 'Disable' : 'Enable'))])],1)],1),_c('can',{attrs:{"do":"delete","on":"management-festivals"}},[_c('b-dropdown-item',{staticClass:"table-row-option-delete",on:{"click":function($event){return _vm.$refs.layout.confirmDelete(data.item, _vm.deleteFestival, _vm.cascadeConfirmDeleteOptions)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]:_vm._e()]}}],null,true)})]}},{key:"debug",fn:function(){return [_c('debug',{attrs:{"collapsed":true}},[_vm._v(_vm._s(_vm.table))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }