export const listFestivals = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                site
                zone {
                    id
                    name
                }
                state {
                    enabled
                }
                createdAt
            }
            nextToken
        }
    }
`;

export const updateFestival = /* GraphQL */ `
    mutation UpdateFestival(
        $input: UpdateFestivalInput!
        $condition: ModelFestivalConditionInput
    ) {
        updateFestival(input: $input, condition: $condition) {
            id
            slug
            name
            zone {
                id
                name
            }
            state {
                enabled
            }
        }
    }
`;
export const deleteFestival = /* GraphQL */ `
    mutation DeleteFestival(
        $input: DeleteFestivalInput!
        $condition: ModelFestivalConditionInput
    ) {
        deleteFestival(input: $input, condition: $condition) {
            id
            slug
            name
            zone {
                id
                name
            }
            state {
                enabled
            }
        }
    }
`;

export const onCreateFestival = /* GraphQL */ `
    subscription OnCreateFestival {
        onCreateFestival {
            id
            slug
            name
            zone {
                id
                name
            }
            state {
                enabled
            }
        }
    }
`;
export const onUpdateFestival = /* GraphQL */ `
    subscription OnUpdateFestival {
        onUpdateFestival {
            id
            slug
            name
            zone {
                id
                name
            }
            state {
                enabled
            }
        }
    }
`;
export const onDeleteFestival = /* GraphQL */ `
    subscription OnDeleteFestival {
        onDeleteFestival {
            id
            slug
            name
            zone {
                id
                name
            }
            state {
                enabled
            }
        }
    }
`;
