<template>
  <b-modal :id="id" centered size="xl" title="New Festival" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                  <b-form-group label="Name" label-class="form-control-label" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-input" v-model="festival.name" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Zone -->
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="zone" name="Zone" rules="required">
                  <b-form-group label="Zone" label-for="zone-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="zone-input"
                              v-model="festival.zone"
                              :loading="options.zones.loading"
                              label="name"
                              :options="options.zones.items"
                              :selectable="option => option.state.enabled === true"
                              :reduce="zone => ({ id: zone.id, name: zone.name })"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"
                              @input="festival.address.county = null">

                      <template #option="{ name, state }">
                        <div class="d-flex justify-content-between align-items-center">
                          <span>{{ name }}</span> <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                        </div>
                      </template>

                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <hr>

            <!-- Site -->
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="site" name="Site" rules="required">
                  <b-form-group label="Site" label-for="site-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="site-input" v-model="festival.site" :state="getValidationState(validationContext)" placeholder="{x} High School, {x} College, etc..."></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Site Address -->
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line1" name="Line 1" rules="required">
                  <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line1-input" v-model="festival.address.line1" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                  <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line2-input" v-model="festival.address.line2" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" sm="6">
                <validation-provider v-slot="validationContext" vid="city" name="City" rules="required">
                  <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="city-input" v-model="festival.address.city" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3" sm="6">
                <validation-provider v-slot="validationContext" vid="county" name="County" rules="required">
                  <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="county-input"
                              v-model="festival.address.county"
                              :options="counties"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3" sm="6">
                <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.state">
                  <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                    <state-input id="state-input"
                                 v-model="festival.address.state"
                                 :validation-state="getValidationState(validationContext)" />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3" sm="6">
                <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.zip">
                  <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="zip-code-input" v-model="festival.address.zip" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="bg-rounded">
                <b-form-group label="Created At" label-class="pt-1">
                  <b-input-group>
                    <b-datepicker v-model="createdAtDate" hide-header nav-button-variant="primary" />
                    <b-timepicker v-model="createdAtTime" close-button-variant="primary" button-variant="primary" hide-header  />
                  </b-input-group>
                </b-form-group>

              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { createFestival, listZones } from '@/graphql/queries/festival-modal';
  import { uuid } from 'vue-uuid';
  import vSelect from 'vue-select'
  import slugify from 'slugify';
  import notify from '@/mixins/notify.mixin'
  import Fuse from 'fuse.js';
  import StateInput from '@/components/StateInput.vue';
  import { rules } from '@/data/validation-rules'
  import {onUpdateZone} from '@/graphql/queries/zones';
  import flatPickr from 'vue-flatpickr-component';
  import settingsMixin from '@/mixins/settings.mixin';

  export default {
    name: 'FestivalModal',
    components: {
      StateInput,
      vSelect,
      flatPickr
    },
    mixins: [ notify, settingsMixin ],
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        festival: {
          name: null,
          zone: null,
          site: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          }
        },
        createdAt: new Date().toISOString(),
        createdAtDate: null,
        createdAtTime: '00:00:59',
        options: {
          zones: {
            items: [],
            loading: true,
            subscription: null
          }
        },
        rules: {
          state: rules.address.state,
          zip: rules.address.zip
        },
        state: {
          loading: true,
          redirect: false
        },
      }
    },
    computed: {
      formattedCreatedAt() {
        return `${this.createdAtDate}T${this.createdAtTime}.999Z`
      },
      counties() {
        if(this.festival && this.festival.zone) {
          const items = this.options.zones.items.find(zone => zone.id === this.festival.zone.id).counties.sort((a, b) => a.localeCompare(b))
          if(!items.includes('TBA')) {
            items.push('TBA')
          }

          return items
        }
        return []
      }
    },
    async created() {
      this.setCreatedAt()
      await this.getZones()
      await this.onUpdateZone()
    },
    beforeDestroy() {
      this.options.zones.subscription.unsubscribe()
    },
    /* eslint-disable prefer-destructuring */
    methods: {
      setCreatedAt() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        this.createdAtDate = this.createdAt.split('T')[0]
        this.createdAtTime = `${hours}:${minutes}:${seconds}`
      },
      async create() {
        try {
          const id = uuid.v4();
          //const slug = slugify(this.festival.name, { lower: true })
          const slug = slugify(`f${this.festival.name}z${this.festival.zone.name}`, { lower: true })
          const festivalInput = {
            id: id,
            slug: slug,
            name: this.festival.name,
            festivalZoneId: this.festival.zone.id,
            site: this.festival.site,
            address: {
              line1: this.festival.address.line1,
              line2: this.festival.address.line2,
              city: this.festival.address.city,
              county: this.festival.address.county,
              state: this.festival.address.state,
              zip: this.festival.address.zip
            },
            state: {
              enabled: true
            },
            createdAt: this.formattedCreatedAt,
          }

          const response = await API.graphql(graphqlOperation(createFestival, { input: festivalInput }));
          const festival = response.data.createFestival

          if(this.state.redirect) {
            await this.$router.push({name: 'management-festival', params: { id: id, slug: slug }})
          }
          else {
            const createdAtDate = new Date(this.formattedCreatedAt);
            const startDateDate = new Date(this.settingsStore.app.current.year.start);
            const endDateDate = new Date(this.settingsStore.app.current.year.end);
            if (createdAtDate > startDateDate && createdAtDate < endDateDate) {
              this.$emit('created', festival)
            }

            this.$root.$emit('bv::toggle::modal', this.id)
            this.notify({ title: 'Success', text: 'Festival was successfully created', icon: 'fas fa-server', variant: 'success' });
          }
        }
        catch (error) {
          console.error(error)
          this.notify({ title: 'Error', text: 'Festival failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      async getZones() {
        const response = await API.graphql(graphqlOperation(listZones));
        this.options.zones.items = response.data.listZones.items.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
        this.options.zones.loading = false
      },
      async onUpdateZone() {
        this.options.zones.subscription = API.graphql(graphqlOperation(onUpdateZone)).subscribe((sourceData) => {
          this.syncNotification()
          const zone = sourceData.value.data.onUpdateZone
          if (zone && this.options.zones.items.map(item => item.id).includes(zone.id)) {
            this.options.zones.loading = true
            const index = this.options.zones.items.findIndex(item => item.id === zone.id)
            if(index > -1) {
              this.options.zones.items.splice(index, 1, zone)
            }
            this.options.zones.loading = false
          }
        });
      },


      clear() {
        this.festival = {
          name: null,
          zone: null,
          site: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          }
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

html[dir='rtl'] {
  .flatpickr-calendar {
    .flatpickr-time {
      display: none!important;
      height: 0!important;
    }
  }
}


.input-group-border {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.input-group:focus-within {
  border-color: var(--primary);
}

</style>
